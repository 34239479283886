











































import FormMixin from '@/mixins/FormMixin'
import FamilySelect from './FamilySelect.vue'
import GroupSelect from './GroupSelect.vue'

export default FormMixin.extend({
  components: {
    FamilySelect,
    GroupSelect
  },
  watch: {
    'form.parent.parent.id': {
      handler(val) {
        this.familyId = val
      },
      immediate: true
    }
  },
  data() {
    return {
      familyId: null
    }
  }
})
